import { render, staticRenderFns } from "./dockingCenter.vue?vue&type=template&id=57344776&scoped=true"
import script from "./dockingCenter.vue?vue&type=script&lang=js"
export * from "./dockingCenter.vue?vue&type=script&lang=js"
import style0 from "./dockingCenter.vue?vue&type=style&index=0&id=57344776&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57344776",
  null
  
)

export default component.exports