
let OSS = require('ali-oss');
const imageConversion = require("image-conversion");


function generateUUID() { // 生成uuid
	var d = new Date().getTime(),
		uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	return uuid;
};

const client = new OSS({ // oss请求配置
	region: 'oss-cn-shenzhen',
	accessKeyId: 'LTAI5t6MfcJVgeYCXoRvGUUM',
	accessKeySecret: 'Ggiw2K3svMQcqF4XhMfmYkHSpTyVs0',
	bucket: 'soprtcore',
    fileHost: 'https://file.sport-syy.com',
    timeOut: 87600
});

// blob转file
function blobFile(blob, file) {
	const files = new window.File([blob], '.jpg', {
		type: file.type
	})
	return files
};

// 图片上传 阿里云 
function uploadAlOss(file,uuid){
    return new Promise( async (resolve, reject) => {
        // 上传阿里云
			const Uuid = uuid || generateUUID();
			const Name = file.name;
			const suffix = Name.substring(Name.indexOf('.')); // 文件后缀
			const year = new Date().getFullYear();
			const month = new Date().getMonth() - 1;
			const day = new Date().getDate();
			const filename = "/visa/"+ year + "/"+ month + "/"+ day + '/web/' + Uuid + suffix; // 组成新文件名
			client.multipartUpload(filename, file).then(res => { // 上传
				resolve(client.options.fileHost + res.name)
				
			}).catch(err => {
				console.error('上传失败：', err)
				reject(err)
			})
    })
};

// 图片压缩
const putBlob = (file, uuid) => {
	return new Promise( async (resolve, reject) => {
        console.log(file.name.substr(file.name.indexOf('.')));
        if (!(file.type.includes('jpeg') || file.type.includes('jpg') || file.type.includes('png') || file.type.includes('apng'))) {
            resolve(uploadAlOss(file,uuid))
        }else{
            //  图片压缩
			//  20240521-批量上传护照前端压缩功能：宽度如果大于1000px，宽度压缩到1000px
			
            let res  = await imageConversion.compressAccurately(file, {
		        size: 100, //The compressed image size is 100kb
		        accuracy: 0.9, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
		        type: "image/jpeg",
		        width: 1000,
		        orientation: 1
            })
            console.log(res,'图片压缩');
            let data = blobFile( res , res )
		    console.log( '转file：' , data )
            resolve(uploadAlOss(data,uuid))
        }
	})
};

export default putBlob
