<template>
    <div class="page personal">
        <div class="page-content">


            <!-- <div class="page-user" >
                <div v-if="mechatinfo" class="page-user-flex">

                    <el-avatar :size="63" :src="mechatinfo.logo"></el-avatar>
                    <div class="page-user-info">
                        <p class="user-name">{{ mechatinfo.companyName }}</p>
                        <p class="user-addr">{{ mechatinfo.addressArea }} </p>
                        <ul class="page-user-pay">
                            <li class="user-pay-item" @click="openOrder(2, 'orderStatus')">
                                <p>待支付 <i class="el-icon-arrow-right"></i></p>
                                <span>{{ orgData.unpaidCount }} <span style="font-size: 14px">单</span> </span>
                            </li>
                            <li class="user-pay-item" @click="openOrder(1, 'ApplcantStatus')">
                                <p>待预审<i class="el-icon-arrow-right"></i></p>
                                <span>{{ orgData.auditCount }}<span style="font-size: 14px">人</span></span>
                            </li>
                            <li class="user-pay-item" @click="openOrder(4, 'ApplcantStatus')">
                                <p>使馆审核<i class="el-icon-arrow-right"></i></p>
                                <span>{{ orgData.embassyCount }}<span style="font-size: 14px">人</span></span>
                            </li>
                            <li class="user-pay-item" @click="openOrder(5, 'ApplcantStatus')">
                                <p>已出签<i class="el-icon-arrow-right"></i></p>
                                <span>{{ orgData.succeedCount }}<span style="font-size: 14px">本</span></span>
                            </li>
                        </ul>
    
                    </div>
                </div>
                <div class="user-right">
                    <span class="user-right-label">可用金额</span>
                    <p class="user-right-price">￥{{ priceNum }}</p>
                </div>
                <img class="img-right" :src="require('@/assets/images/personal/logo.png')" />
            </div> -->
            <div class="page-path" v-if="menuActive == 1 && productEditShow">
                <p><span class="page-page-to" @click="openProduct">产品管理</span> <i class="el-icon-arrow-right"></i>
                    <span>{{ productId ? '编辑产品' : '新增产品' }}</span>
                </p>
            </div>
            <div class="page-path" v-if="menuActive == 1 && setting.id">
                <p><span class="page-page-to" @click="openProduct">产品管理</span> <i class="el-icon-arrow-right"></i>
                    <span>资料配置</span>
                </p>
            </div>
            <div class="page-path" v-if="menuActive == 2 && openQuanPath">
                <p><span class="page-page-to" @click="openQuanPath = false">充值套餐</span> <i class="el-icon-arrow-right"></i>
                    <span>优惠券管理</span>
                </p>
            </div>

            <div class="page-path" v-if="menuActive == 3 && kfShow">
                <p><span class="page-page-to" @click="kfShow = false">商户管理</span> <i class="el-icon-arrow-right"></i>
                    <span>客服管理</span>
                </p>
            </div>
            <div class="page-box">
                <div class="page-box-left">
                    <div class="page-box-item" @click="checkMenu(item)"
                        :class="[menuActive == item.sort ? 'page-box-item-active' : '']" v-for="(item, index) in menuList"
                        :key="index">
                        <div class="page-box-icon" :class="[item.icon,]"></div>
                        <div class="page-box-label">{{ item.name }}</div>
                    </div>
                </div>

                <!-- 签证工具 -->
                <div class="packageVisa" v-if="menuActive == 0">
                    <visa></visa>
                </div>
                <!-- 产品管理 -->
                <template v-if="menuActive == 1">
                    <div class="product">
                        <!-- 列表 -->
                        <!-- <product @open="showEditProduct" @openSettings="showSetting"
                            :init="productEditShow"></product> -->
                        <product-page></product-page>
                    </div>

                </template>

                <!-- 充值套餐 -->
                <div class="package" v-if="menuActive == 2">
                    <!-- 套餐列表 -->
                    <package-page @toQuan="toQuan" v-if="!openQuanPath"></package-page>

                    <package-quan v-if="openQuanPath"></package-quan>
                </div>

                <!-- 商户管理 -->
                <div class="package" v-if="menuActive == 3">
                    <merchant @toKf="toKf" v-if="!kfShow"></merchant>

                    <service v-else></service>
                </div>

                <!-- 财务管理 -->
                <div class="package" v-if="menuActive == 4">
                    <finance></finance>
                </div>
                <!-- 账号管理 -->
                <div class="package" v-if="menuActive == 5">
                    <account></account>
                </div>
                <!-- 对接中心 -->
                <div class="package" v-if="menuActive == 6">
                    <dockingCenter></dockingCenter>
                </div>
                <!-- 应用管理 -->
                <div class="package" v-if="menuActive == 7">
                    <applicationManage></applicationManage>
                </div>
                 <!-- 资讯管理 -->
                 <div class="package" v-if="menuActive == 8">
                    <editInfo v-if="isEditInfo" :infoFrom="infoFrom" @goBack="close"></editInfo>
                    <information @editInfo="changeEditInfo" v-else></information>
                </div>
            </div>
        </div>
        <recharge :config="isVisible" v-if="isVisible" @close="isVisible = false"></recharge>
    </div>
</template>

<script>
import { getmechatinfo, getbalance, updatemerchatmember, getorgdata } from '@/api/personal.js'
import { getuserinfo ,getorgauthinfo } from '@/api/common.js'
// import { getLocalStorage } from '@/utils/auth'
import recharge from '@/components/recharge.vue';
import productPage from '@/views/product/productManage/index.vue';
import packagePage from '@/views/settings/components/package.vue';
import packageQuan from '@/views/settings/components/packageQuan.vue';
import merchant from '@/views/settings/components/merchant';
import finance from '@/views/settings/components/finance.vue';
// import service from '@/views/settings/components/service.vue';
import account from '@/views/settings/components/account.vue';
import dockingCenter from '@/views/settings/components/dockingCenter.vue';
import applicationManage from '@/views/settings/components/applicationManage.vue';
import visa from "@/views/settings/components/visa.vue";
import information from "@/views/settings/components/information.vue";
import editInfo from './components/editInfo.vue';
import { EventBus } from '@/utils/eventBus.js';

export default {
    components: {
        recharge,
        productPage,
        packagePage,
        packageQuan,
        merchant,
        // service,
        finance,
        account,
        dockingCenter,
        applicationManage,
        visa,
        information,
        editInfo
    },
    data() {
        var validatorAccount = (rule, value, callback) => {
            let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入手机号'));
            }


            if (!reg.test(value)) {
                return callback(new Error('手机号格式不正确'));
            } else {

                callback();
            }


        };

        var validatorEmail = (rule, value, callback) => {
            let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入邮箱地址'));
            }


            if (!reg.test(value)) {
                return callback(new Error('请输入正确的邮箱地址格式'));
            } else {

                callback();
            }


        };
        return {
            userInfo: {
            },
            mechatinfo: {},
            priceNum: 0,
            menuList: [
                // { name: '产品管理', sort: 1, icon: 'iconfont icon-chanpinguanli' },
                // { name: '充值套餐', sort: 2, icon: 'iconfont icon-taocanmoban' },
                // { name: '商户管理', sort: 3, icon: 'iconfont icon-a-zu331' },
                // { name: '财务管理', sort: 4, icon: 'iconfont icon-caiwuguanli' },
                // { name: '账号管理', sort: 5, icon: 'iconfont icon-zhanghaoguanli' },
                // { name: '对接中心', sort: 6, icon: 'iconfont icon-shujuduijie' },
            ],
            menuActive: 0,
            productId: '',
            loading: false,
            financeLoading: false,
            openQuanPath: false,
            kfShow: false,
            status: 0,
            circleUrl: '',
            setting: {
                id: '',
            },

            dialogMember: false,
            isVisible: false,
            isEdit: false,
            productEditShow: false,

            rules: {
                account: [
                    { validator: validatorAccount, required: true, message: '请输入账号', trigger: ['click', 'change', 'blur'] }
                ],
                name: [
                    { required: true, message: '请输入联系人', trigger: ['click', 'change', 'blur'] },
                ],
                phone: [
                    { validator: validatorAccount, required: true, trigger: ['click', 'change', 'blur'] }
                ],
                email: [
                    { validator: validatorEmail, required: true, trigger: ['click', 'change', 'blur'] },
                ]

            },
            orgData: {},
            orgAuthInfo: null,
            isEditInfo:false,
            infoFrom:{},
        }
    },
    computed:{
        style(){
            return this.menuActive == 0?"height:0":"height:100%"
        }
    },
    created() {
        EventBus.$on('checkFinance', () => {
            this.checkMenu({ sort: 4 })
        });
        this.getOrgAuthInfo()
    },
    mounted() {
        // this.init()
        
        // 其他地址页面跳转财务管理页
        if(this.$route && this.$route.params && this.$route.params.menuActive) {
            setTimeout(() => {
                this.checkMenu({ sort: 4 })
            }, 100);
        }
    },
    methods: {
        getOrgAuthInfo() {
            getorgauthinfo().then(res => {
                this.orgAuthInfo = res.data
                this.init()
            })
        },
        init() {
            if (!this.orgAuthInfo) {
                this.getmechatinfoFn();
            } else {
                this.menuList = this.orgAuthInfo.menus[0].children
                this.menuList.push({ sort: 8, name: '资讯管理',icon:'iconfont  icon-zixun'})
                this.menuActive=this.menuList[0].sort;
            }
            this.getbalanceFn();
            this.getorgdataFn();
        },
        getorgdataFn() {
            getorgdata({}).then(res => {
                this.orgData = res.data;
            })
        },
        openOrder(status, str) {
            var query = {}
            if (str == 'orderStatus') {
                query = {
                    OrderStatus: status
                }
            } else {
                query = {
                    ApplcantStatus: status
                }
            }
            this.$router.push({ name: 'order', query })
        },
        getmechatinfoFn() {
            getmechatinfo({}).then(res => {
                this.mechatinfo = res.data
            })
        },
        getbalanceFn() {
            getbalance({}).then(res => {
                this.priceNum = res.data
            })
        },
        toQuan() {
            this.openQuanPath = true;
        },
        toKf() {
            this.kfShow = true;
        },
        getQueryaddress() {
            this.loading = true;
            queryaddress({
                'Page.Size': 6,
                'Page.Index': this.addressf.pageIndex,
            }).then(res => {
                this.loading = false;
                this.address = res.data.rows;
                this.addressf.pageTotal = res.data.total
                this.address.forEach(item => {
                    item.isShow = false;
                })
            })
        },
        getuserinfoFn() {
            getuserinfo({
            }).then(res => {
                this.userInfo = res.data
                this.merchatAccountView = this.userInfo.merchatAccountView
            })
        },
        memberCurrentChange(e) {
            this.member.pageIndex = e;
            this.getQuerymerchatmembers();
        },
        addMember() {
            this.dialogMember = true
        },
        showEditProduct(e) {
            if (e) {
                this.productId = e;
            } else {
                this.productId = '';
            }

            this.productEditShow = true;
        },
        showSetting(item) {
            this.setting = item;
        },
        openProduct() {
            this.productEditShow = false;
            this.setting = {
                id: '',
            }
        },
        addAddress() {
            this.addresForm = {}
        },
        yrChange(item) {
            this.address.forEach(elem => {
                if (item.id == elem.id) {
                    elem.isShow = true;
                } else {
                    elem.isShow = false;
                }
            })
            this.$forceUpdate();
        },
        ycChange(item) {
            this.address.forEach(elem => {
                // if (item.id == elem.id) {
                //     elem.isShow = true;
                // } else {
                // }
                elem.isShow = false;
            })
            this.$forceUpdate();
        },
        editUser() {
            this.isEdit = true;
        },
        editUserInfo() {


            if (!this.merchatAccountView.name) {
                return;
            }
            if (!this.merchatAccountView.phone) {
                return;
            }
            if (!this.merchatAccountView.email) {
                return;
            }
            updatemerchatmember({
                id: this.merchatAccountView.id,
                name: this.merchatAccountView.name,
                phone: this.merchatAccountView.phone,
                email: this.merchatAccountView.email,
                account: this.merchatAccountView.account
            }).then(res => {
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
                this.isEdit = false;
            })
        },
        checkMenu(item) {
            this.menuActive = item.sort
            if (item.sort == 1) {
                this.openProduct();
            }

            if (item.sort == 2) {

                this.openQuanPath = false;
            }

            if (item.sort == 3) {
                this.kfShow = false;
            }

            if (item.sort == 4) {

            }

            if (item.sort == 5) {
                // this.getuserinfoFn();
            }

            if (item.sort == 6) {
                // this.productEditShow = false;
            }
        },
        changeEditInfo(item){
            this.infoFrom=item&&JSON.parse(JSON.stringify(item));
            this.isEditInfo=!this.isEditInfo;
        },
        close(){
            this.isEditInfo=!this.isEditInfo; 
        }
    }
}


</script>

<style lang="scss" scoped>
.personal {

    .el-pager {
        .active {
            cursor: default;
            background: #1492E6;
            color: #FFF !important;
            border-radius: 100%;
            width: 28px;
            height: 28px;
            min-width: 10px;
            box-shadow: 0px 2px 3px 1px rgba(19, 152, 231, 0.26);
        }
    }

    .el-table__header-wrapper {
        border-radius: 8px 8px 0 0;
    }
}


.page-path {
    margin: 8px 0;

    p {
        font-size: 12px;
        color: #555555;
    }

    .page-page-to {
        cursor: pointer;
    }
}

.account-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 631px;
        height: 323px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}


.demo-ruleForm {
    .el-form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
        }

        .el-form-item__content {
            margin-left: 0px !important;
        }
    }

    .el-form-item__content {
        text-align: right;

        .el-button {
            margin-top: 20px;
        }
    }
}

.member-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 372px;
        height: 441px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }

    &.address-class {
        .el-dialog__body {
            height: 519px;

        }
    }
}


.page {
    background: #F7F8FA;
    padding-bottom: 24px;
}

.address {
    flex: 1;
    padding: 20px 28px;
    box-sizing: border-box;
    position: relative;
    // min-height: 600px;

    .box-input {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .address-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 60px;


        .address-item {
            width: 273px;
            height: 158px;
            box-shadow: 0px 3px 3px 1px rgba(#000000, 0.1);
            position: relative;
            padding: 16px;
            box-sizing: border-box;
            margin-right: 28px;
            margin-bottom: 28px;

            .address-is-default {
                position: absolute;
                width: 60px;
                height: 23px;
                line-height: 23px;
                background: #3888E9;
                border-radius: 4px 0px 0px 4px;
                top: 16px;
                right: 0;
                font-size: 12px;
                color: #FFF;
                text-align: center;

            }

            &::before {
                content: "";
                background: url('../../assets/images/personal/top-x.png');
                background-size: 100%;
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                height: 2px;
            }

            .address-item-top {
                display: flex;
                align-items: flex-end;

                p {
                    font-size: 16px;
                    font-weight: bold;
                }

                span {
                    font-size: 12px;
                    color: #777777;
                    margin-left: 8px;
                }
            }

            .address-center {
                font-size: 12px;
                color: #444444;
                margin-top: 16px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                height: 33px;
            }

            .address-email {
                margin-top: 8px;
                font-size: 12px;
            }

            .address-btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 12px;

                .address-btn-1 {
                    background: #3888E9;
                    width: 64px;
                    height: 22px;
                    line-height: 22px;
                    border-radius: 4px;
                    font-size: 10px;
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .address-del {
                    width: 22px;
                    height: 22px;
                    border-radius: 4px;
                    background: #E41313;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 8px;
                    cursor: pointer;

                    i {
                        color: #FFF;
                    }
                }

            }
        }
    }
}


.page-user {
    //width: 1200px;
    width: 100%;
    height: 194px;
    background: #3888E9;
    border-radius: 8px;
    margin-top: 24px;
    padding: 24px 20px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 24px;

    .img-right {
        position: absolute;
        right: 110px;
        width: 178px;
        height: 170px;



    }

    .page-user-flex {
        display: flex;
    }

    .page-user-info {
        display: flex;
        flex-direction: column;
        margin-left: 27px;

        .user-name {
            font-size: 28px;
            color: #FFF;
            font-weight: bold;
        }

        .user-addr {
            font-size: 16px;
            color: #FFF;
            // margin-top: 4px;
        }

        .page-user-pay {
            display: flex;
            // margin-top: 18px;

            .user-pay-item {
                width: 141px;
                height: 70px;
                background: #FFF;
                border-radius: 8px;
                margin-right: 16px;
                display: flex;
                flex-direction: column;
                padding: 8px 16px;
                box-sizing: border-box;
                cursor: pointer;

                p {
                    font-size: 12px;
                    color: #666666;

                }

                span {
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 4px;
                }
            }
        }
    }

    .user-right {
        flex: 1;
        text-align: right;

        .user-right-label {
            font-size: 16px;
            color: #FFF;
        }

        .user-right-price {
            font-size: 28px;
            color: #FFF;
            font-weight: bold;
        }

        .price-btn {
            background: #F8912C;
            color: #FFF;
            font-size: 16px;
            border: 1px solid #F8912C;
            margin-top: 49px;
        }
    }
}


@media (min-width:1280px) {
    .page-content {
      width: 87vw !important;
    }
}
@media (max-width: 1280px) {
    .page-content {
      width: 1200px  !important;
    }
}

.page-content {
    width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 100px - 40px);
    padding-top: 20px;
}

.page-box {
    //width: 1200px;
    width: 100%;
    background: #FFF;
    border-radius: 8px;
    // height: 645px;
    // margin-top: 24px;
    // min-height: 600px;
    display: flex;
    margin-bottom: 50px;
    height: 100%;
    position: relative;
    // 平台设置高度铺满
    .product,
    .package {
        width: 100%;
        height: 100%;
        overflow: auto;

        ::v-deep {

            // .product-user, .package-page, .merchant，.package-quan-wrap {
            >div {
                height: calc(100% - 32px);

                .box-table {
                    height: calc(100% - 100px);

                    .el-table {
                        height: calc(100%) !important;
                    }
                }
            }
        }
    }

    .packageVisa{
        width:100%;
        padding: 16px 0 16px 18px;
        overflow: auto;
    }
    .page-box-left {
        width: 187px;
        // height: 100%;
        border-right: 1px solid #E6E6E6;
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;

        .page-box-item {
            width: 146px;
            display: flex;
            align-items: center;
            height: 46px;
            border-radius: 8px;
            margin-bottom: 12px;
            cursor: pointer;
            &:hover{
                background: #F2F6FF; 
                color: #3888E9;
            }
            .page-box-icon {
                margin-left: 20px;
                margin-right: 13px;
            }

            .page-box-label {
                font-size: 16px;
            }

            &.page-box-item-active {

                background: #F2F6FF;

                .page-box-icon {
                    color: #3888E9;
                }

                .page-box-label {
                    color: #3888E9;

                }
            }

        }
    }

    .box-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .button-color {
            border: 1px solid #3CA4E9;
            color: #3CA4E9;
        }

    }



    .table-pagination {
        text-align: center;
        margin-top: 16px;
    }


}
</style>